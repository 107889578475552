<template>
  <div class="box">
    <el-form
      ref="ruleForm"
      class="ruleForm"
      :model="info"
      :rules="rules"
      size="small"
      label-width="100px"
      label-position="right"
    >
      <el-form-item label="图片" prop="img">
        <SelectImgs :selectNum="1" :selectData="[info.img]" :selectFunc="picChoose" :idx="1"></SelectImgs>
        <div style="margin-top: 40px">919*517px</div>
      </el-form-item>
      <el-form-item label="链接" prop="url">
        <el-input v-model="info.url" placeholder="请输入链接"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="ordering">
        <el-input v-model="info.ordering" placeholder="请输入序号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import SelectImgs from '@/components/SelectImgs.vue'

export default {
  components: {SelectImgs},
  data() {
    return {
      info: {},
      rules: {}
    }
  },
  created() {
    if (this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  methods: {
    getInfo() {
      var that = this
      this.$api.goods.goodsShowInfo({id: that.info.id}, function (res) {
        if(res.errcode == 0) {
          that.info = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    picChoose(item) {
      this.$set(this.info, 'img', item.pic)
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = that.info
          this.$api.goods.goodsShowEdit(param,function (res) {
            if(res.errcode == 0) {
              that.success(res.errmsg)
              that.reBack()
            } else {
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>
.box {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  min-height: 500px;
}
</style>